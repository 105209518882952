.titleContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  width: max-content;

  h2 {
    &::before {
      content: '';
      width: 25px;
      height: 5px;
      background: var(--cyanGradients);

      left: 0;
      top: 0;
      position: absolute;
    }
    &::after {
      content: '';
      width: 25px;
      height: 5px;
      background: var(--cyanGradients);

      right: 0;
      bottom: 0;
      position: absolute;
    }
  }
}
