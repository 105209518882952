@use '../../styles/breakpoints.scss';

.diferenciais-container {
  display: flex;  
  align-items: center;
  flex-direction: column;

  padding: 3rem 1.5rem;

  @include breakpoints.and-up(lg) {
    padding: 5rem 0.5rem;
  }
}

.diferenciais-grid {  
  width: 100%;
  padding-top: 3rem;
  
  display: grid;
  row-gap: 3rem;
  grid-template-rows: repeat(6, 1fr);
  
  @include breakpoints.and-up(lg) {
    display: grid;
    row-gap: 3rem;
    column-gap: 1.5rem;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);
  }
}
