@use 'sass:map';
@use '../styles/typography.scss';

.input-file-section {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;

  input[type='file'] {
    display: none;
  }

  label {
    background-color: transparent;
    border: 1px solid var(--gray300);

    color: var(--text);
    cursor: pointer;
    font-size: map.get(typography.$sizes, 'b1');
    font-weight: map.get(typography.$weights, 'b1');
    line-height: map.get(typography.$line-heights, 'b1');
    padding: 1rem;
    outline: 0;
    transition: background 0.2s;

    &:hover {
      background-color: var(--gray300);
    }
  }
}
