@use 'sass:map';
@use '../../styles/typography.scss';
@use '../../styles/breakpoints.scss';

.inicio-section {
  height: 100vh;
  position: relative;
  background: url('../../assets/hero-background.jpg') center center no-repeat;
  background-size: cover;
}

.inicio-container {
  height: 100vh;
  display: flex;
  align-items: center;

  padding: 0 1.5rem;
  
  @include breakpoints.and-up(lg) {
    width: 50vw;
    padding: 0 0.5rem;
    flex-direction: row;
  }
}

.inicio-description {
  display: flex;
  flex-direction: column;
  
  row-gap: 2rem;

  @include breakpoints.and-up(lg) {
    align-items: flex-start;
    text-align: left;
  }
}

.button {
  margin-top: 1rem;
  background: var(--cyanGradients);
  padding: 0.75rem 1.75rem;
  width: max-content;
  text-transform: uppercase;
  transition: filter 0.2s;
  color: var(--blue700);
  font-size: 0.875rem;

  &:hover {
    filter: brightness(0.9)
  }
}