@use 'sass:map';
@use '../typography.scss';
@use '../colors.scss';


// Headings

.heading1 {
  font-size: map.get(typography.$sizes, 'h1');
  font-weight: map.get(typography.$weights, 'h1');
  line-height: map.get(typography.$line-heights, 'h1');
  font-family: 'Space Mono', sans-serif;
}

.heading2 {
  font-size: map.get(typography.$sizes, 'h2');
  font-weight: map.get(typography.$weights, 'h2');
  line-height: map.get(typography.$line-heights, 'h2');
  font-family: 'Space Mono', sans-serif;
}

.heading3 {
  font-size: map.get(typography.$sizes, 'h3');
  font-weight: map.get(typography.$weights, 'h3');
  line-height: map.get(typography.$line-heights, 'h3');
  font-family: 'Space Mono', sans-serif;
}

.heading4 {
  font-size: map.get(typography.$sizes, 'h4');
  font-weight: map.get(typography.$weights, 'h4');
  line-height: map.get(typography.$line-heights, 'h4');
  font-family: 'Space Mono', sans-serif;
}

.heading5 {
  font-size: map.get(typography.$sizes, 'h5');
  font-weight: map.get(typography.$weights, 'h5');
  line-height: map.get(typography.$line-heights, 'h5');
  font-family: 'Space Mono', sans-serif;
}

.heading6 {
  font-size: map.get(typography.$sizes, 'h6');
  font-weight: map.get(typography.$weights, 'h6');
  line-height: map.get(typography.$line-heights, 'h6');
  font-family: 'Lato', sans-serif;
}

// paragraphs

.paragraphsBig {
  font-size: map.get(typography.$sizes, 'b1');
  font-weight: map.get(typography.$weights, 'b1');
  line-height: map.get(typography.$line-heights, 'b1');
  font-family: 'Lato', sans-serif;
}

.paragraphsNormal {
  font-size: map.get(typography.$sizes, 'b2');
  font-weight: map.get(typography.$weights, 'b2');
  line-height: map.get(typography.$line-heights, 'b2');
  font-family: 'Lato', sans-serif;
}

.paragraphsBold {
  font-size: map.get(typography.$sizes, 'b3');
  font-weight: map.get(typography.$weights, 'b3');
  line-height: map.get(typography.$line-heights, 'b3');
  font-family: 'Lato', sans-serif;
}

.body4 {
  font-size: map.get(typography.$sizes, 'b4');
  font-weight: map.get(typography.$weights, 'b4');
  line-height: map.get(typography.$line-heights, 'b4');
  font-family: 'Lato', sans-serif;
}

// Captions

.caption1 {
  font-size: map.get(typography.$sizes, 'caption1');
  font-weight: map.get(typography.$weights, 'caption1');
  line-height: map.get(typography.$line-heights, 'caption1');
  font-family: 'Lato', sans-serif;
}

.caption2 {
  font-size: map.get(typography.$sizes, 'caption2');
  font-weight: map.get(typography.$weights, 'caption2');
  line-height: map.get(typography.$line-heights, 'caption2');
  font-family: 'Lato', sans-serif;
}
