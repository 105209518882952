@use "sass:map";
@use "../styles/breakpoints.scss";
@use "../styles/colors.scss";
@use "../styles/typography.scss";

.input-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
}

.input-field {
  background-color: colors.$gray100;
  border: 0;
  border-bottom: 1px solid colors.$gray100;
  color: colors.$text;
  font-size: map.get(typography.$sizes, "b1");
  font-weight: map.get(typography.$weights, "b1");
  line-height: map.get(typography.$line-heights, "b1");
  padding: 1rem;
  outline: 0;
  width: 100%;

  &:hover {
    border-color: colors.$gray400;
  }

  &::placeholder {
    color: colors.$gray400;
  }

  &:focus {
    border-color: colors.$cyan500;
    box-shadow: 0 0 2px rgba(8, 103, 161, 0.5);
  }
}
