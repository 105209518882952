.container{
  display: flex;
  padding: 0.5rem 2rem;

  & > :hover{ 
    transform: scale(1.02);
  }
}

.content{
  display: flex;
  padding: 1.5rem;
  min-height: 320px;
  
  transition: all 0.3s;
  
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.1);
  
  flex-direction: column;

  & > h4, p{
    margin-bottom: 0.2rem;
    margin-left: 2rem;
  }
}

.description{
  display: flex;
  text-align: justify;

  padding: 0 0.5rem;

  p{
    margin-top: 0.5rem;
    margin-left: 1rem;
  }
}