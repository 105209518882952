@use "../styles/breakpoints.scss";
@use "../styles/colors.scss";

.footer-section {
  background-color: colors.$gray800;
  box-shadow: 0px -1px 1px rgba(191, 205, 217, 0.5);
}

.footer-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 2rem 1.5rem;

  @include breakpoints.and-up(md) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    column-gap: 1rem;
    padding: 2rem 0.5rem;
  }

  @include breakpoints.and-up(xl) {
    justify-content: space-between;
  }
}

.footer-item {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;

  h5 {
    color: colors.$cyan400;
  }

  p {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;

    color: colors.$gray50;

    max-width: 295px;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.footer-navigation {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  margin-top: 0.5rem;

  li {
    list-style: none;
  }

  a {
    color: colors.$gray50;
  }
}

.see-route-link {
  transition: filter 0.2s;

  color: colors.$gray400;

  &:hover {
    filter: brightness(0.7);
  }
}

.map-container {
  width: 280px;
  height: 250px;
  border: 1px solid colors.$gray300;
  border-radius: 6px;
  overflow: hidden;

  @include breakpoints.and-up(md) {
    width: 530px;
    height: 300px;
  }

  @include breakpoints.and-up(xl) {
    width: 300px;
    height: 250px;
  }
}

.social-media-icons {
  display: flex;
  column-gap: 0.75rem;
  margin: 0.5rem 0;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 0.2s;

    width: 28px;
    height: 28px;

    img {
      width: 100%;
      height: 100%;
    }

    &:hover {
      filter: brightness(0.85);
    }
  }
}

.logo-image {
  font-size: 0;
  user-select: none;

  img {
    width: 100%;
    height: 100%;
    max-height: 56px;
  }
}

.policy-container {
  background-color: #0f172a;
  padding: 1rem;
  
  & > div {
    @include breakpoints.and-up(md) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    & > p:first-child {
      width: 100%;
      color: colors.$gray500;
      text-align: center;

      @include breakpoints.and-up(md) {
        text-align: left;
      }
    }
  }
}

.policy-content {
  display: flex;
  align-items: center;
  width: 100%;

  justify-content: center;

  @include breakpoints.and-up(md) {
    justify-content: right;
  }

  & > a:last-child::before {
    content: "|";
    margin: 0 0.5rem 0 0.5rem;

    width: 10px;
    height: 10px;

    color: colors.$gray500;
  }

  & > a:first-child::after {
    content: "|";
    margin: 0 0.5rem 0 0.5rem;

    width: 10px;
    height: 10px;

    color: colors.$gray500;
  }
}
