@use 'sass:map';
@use '../styles/typography.scss';

.button {
  background: var(--cyanGradients);
  border: 0;
  color: var(--blue700);
  font-size: map.get(typography.$sizes, 'b4');
  font-weight: map.get(typography.$weights, 'b2');
  line-height: map.get(typography.$line-heights, 'b2');
  padding: 0.75rem 1.75rem;
  width: max-content;
  text-transform: uppercase;
  transition: filter 0.2s;


  &:hover {
    filter: brightness(0.9)
  }

  &:focus-visible {
    outline-color: var(--text);
    outline-offset: 2px;
  }
}
