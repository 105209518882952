@use "../colors.scss";

$all_colors: (
  gray50: colors.$gray50,
  gray100: colors.$gray100,
  gray200: colors.$gray200,
  gray300: colors.$gray300,
  gray400: colors.$gray400,
  gray500: colors.$gray500,
  gray600: colors.$gray600,
  gray700: colors.$gray700,
  gray800: colors.$gray800,
  gray900: colors.$gray900,

  cyan50: colors.$cyan50,
  cyan100: colors.$cyan100,
  cyan200: colors.$cyan200,
  cyan300: colors.$cyan300,
  cyan400: colors.$cyan400,
  cyan500: colors.$cyan500,
  cyan600: colors.$cyan600,
  cyan700: colors.$cyan700,
  cyan800: colors.$cyan800,
  cyan900: colors.$cyan900,

  blue50: colors.$blue50,
  blue100: colors.$blue100,
  blue200: colors.$blue200,
  blue300: colors.$blue300,
  blue400: colors.$blue400,
  blue500: colors.$blue500,
  blue600: colors.$blue600,
  blue700: colors.$blue700,
  blue800: colors.$blue800,
  blue900: colors.$blue900,

  infoBlue: colors.$infoBlue,
  infoWarning: colors.$infoWarning,
  infoSuccess: colors.$infoSuccess,
  infoRed: colors.$infoRed,

  cyanGradients: colors.$cyanGradients,
  title: colors.$title,
  text: colors.$text,
  textLight: colors.$textLight,

  background: colors.$background,
  white: colors.$white,
);

@each $color, $value in $all_colors {
  .#{"" + $color} {
    color: $value;
  }

  .bg-#{"" + $color} {
    background: $value;
  }

  .hover-#{"" + $color} {
    &:hover {
      color: $value !important;
    }
  }

  .hover-bg-#{''+ $color} {
    &:hover {
      background: $value;
    }
  }
}