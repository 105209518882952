@use '../../styles/breakpoints.scss';

.contato-container {
  display: flex;
  
  padding: 5rem 0;

  align-items: center;
  flex-direction: column;
}

.contato-section {  
  width: 100%;
  height: 100%;

  margin-top: 3rem;
  padding: 0 1.5rem;

  @include breakpoints.and-up(lg) {
    padding: 0 0.5rem;
  }
}

.flex{
  display: flex;
  row-gap: 1rem;
  flex-direction: column;

  @include breakpoints.and-up(lg) {
    flex-direction: row;
    column-gap: 1rem;
  }
}

.contact-form{    
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  @include breakpoints.and-up(lg) {
    padding: 0rem 8rem;
  }
}

.recaptcha-content {
  gap: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  button{
    width: 100%;
  }
  
  @include breakpoints.and-up(lg) {
    align-items: flex-start;
    
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.rotate {
  animation: loadingAnimation 1s linear infinite;
}

@keyframes loadingAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}