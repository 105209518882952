$gray50: #f8fafc;
$gray100: #f1f5f9;
$gray200: #e2e8f0;
$gray300: #cbd5e1;
$gray400: #94a3b8;
$gray500: #64748b;
$gray600: #475569;
$gray700: #334155;
$gray800: #1e293b;
$gray900: #0f172a;

$cyan50: #d5f3f2;
$cyan100: #abe7e6;
$cyan200: #81dbda;
$cyan300: #56cfce;
$cyan400: #2dc3c2;
$cyan500: #28afae;
$cyan600: #249c9b;
$cyan700: #1f8887;
$cyan800: #1b7574;
$cyan900: #166161;

$blue50: #d2d9e5;
$blue100: #a6b3cb;
$blue200: #7a8eb1;
$blue300: #4e6897;
$blue400: #22437e;
$blue500: #1e3c71;
$blue600: #1b3564;
$blue700: #172e58;
$blue800: #14284b;
$blue900: #11213f;

$infoBlue: #36b8e1;
$infoWarning: #ffcb44;
$infoSuccess: #55d055;
$infoRed: #f03737;

$cyanGradients: linear-gradient(90deg, #28afae 0%, #56cfce 100%);

$title: $gray900;
$text: $gray600;
$textLight: $gray400;

$background: $gray50;
$white: #ffffff;