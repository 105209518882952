@use "../styles/breakpoints.scss";
@use "../styles/colors.scss";

.error-message-container {
  display: flex;
  align-items: center;
  gap: 0.3rem;

  color: red;
  margin-top: 0.3rem;
}
