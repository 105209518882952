@use 'sass:map';
@use '../../styles/typography.scss';
@use '../../styles/breakpoints.scss';


.container-hero{
  display: flex;
  align-items: center;
  flex-direction: row;

  column-gap: 1rem;
  padding: 3rem 0rem;
  
  border-bottom: 2px solid;
  border-color: var(--gray200);

  margin: 0 1.5rem;

  @include breakpoints.and-up(lg) {
    margin: 0 0.5rem;
    padding: 3rem 0rem;
  }
}

.paragraphs-container{
  width: 100%;
}

.paragraphs-content{
  h5{
    margin: 1rem 0rem;
  }

  @include breakpoints.and-up(lg) {
    max-width: 530px;
  }
}

.image-container{
  display: none;
  @include breakpoints.and-up(lg) {
    display: inline;
  }
}

.container-vagas{
  padding: 3rem 1.5rem;

  p{
    margin: 1rem 0rem;
  }

  a{
    color: var(--text)
  }

  @include breakpoints.and-up(lg) {
    padding: 3rem 0.5rem;
  }
}

.title-table{
  margin-top: 3rem;
  margin-bottom: 0.5rem;
}

.button-vagas{
  width: 100%;
  border: none;
  background:transparent;
  
  text-align: start;

}

.row-table {
  width: 100%;

  border-bottom: 1px solid;
  border-color: var(--gray200);

  cursor: pointer;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.button-submit{
  background: var(--cyanGradients);
  border: 0;
  color: var(--blue700);
  font-size: map.get(typography.$sizes, 'b4');
  font-weight: map.get(typography.$weights, 'b2');
  line-height: map.get(typography.$line-heights, 'b2');
  padding: 0.75rem 1.75rem;
  width: 100%;
  text-transform: uppercase;
  transition: filter 0.2s;

  margin-bottom: 2rem;

  display: flex;
  justify-content: center;
  align-items: center;

  @include breakpoints.and-up(lg) {
    width: max-content;
  }


  &:hover {
    filter: brightness(0.9)
  }

  &:focus-visible {
    outline-color: var(--text);
    outline-offset: 2px;
  }
}

.anexo-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 0rem 1.5rem;
  
  @include breakpoints.and-up(lg) {
    margin: 0 0.5rem;
    padding: 0rem;
  }
}

.anexo-content {
  width: 100%;
  display: flex;
  gap: 1rem;

  align-items: center;

  margin: 1rem 0rem;  

  @include breakpoints.and-up(lg) {
    flex-direction: row;
  }
}

.rotate {
  animation: loadingAnimation 1s linear infinite;
  margin-right: 0.5rem;
}

@keyframes loadingAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}