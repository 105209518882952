@use 'sass:map';
@use '../styles/typography.scss';
@use '../styles/breakpoints.scss';
@use '../styles/colors.scss';

.header {
  background-color: var(--white);
  box-shadow: 0px 1px 0px rgba(191, 205, 217, 0.5);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;

  padding: 0.5rem 0;
}

.header-navbar-container {
  width: 100%;
  padding: 0rem 1.5rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  button{
    border: none;
  }

  @include breakpoints.and-up(lg) {
    padding: 0 0.5rem;
  }
}

.tooltip-contato{
  padding: 1rem;
  border-radius: 2px;
  backdrop-filter: blur(0.5rem);
  background: rgba(0, 0, 0, .3);

  display: flex;
  position: absolute;
  flex-direction: column;

  right: -85px;
  row-gap: 0.5rem;
  margin-top: 1.8rem;

  a{
    color: white;

  }
  :hover{
    filter: brightness(0.9);
  }
  
  display: none;

  @include breakpoints.and-up(lg) {
    display: flex;
  }
}

.navbar {
  background-color: rgba(241, 241, 241, 0.95);
  width: 100%;
  height: 100vh;

  visibility: visible;
  opacity: 1;

  position: fixed;
  top: 4rem;
  left: 0;
  bottom: 0;

  @include breakpoints.and-up(md) {
    top: 3.9rem;
  }

  @include breakpoints.and-up(lg) {
    background: transparent;
    position: initial;
    width: max-content;
    height: auto;
  }
}

.navbar-list {
  display: flex;
  flex-direction: column;

  position: relative;  

  li {
    width: 100%;
    list-style: none;
    margin-bottom: 1rem;

    a {
      color: var(--title);
      font-size: map.get(typography.$sizes, 'h4');
      font-weight: map.get(typography.$weights, 'h4');
      line-height: map.get(typography.$line-heights, 'h4');
      padding: 0.75rem 0.5rem;
      width: 100%;
      transition: color 0.2s;

      &:focus-visible {
        outline-color: var(--text-light);
      }
    }
  }

  padding: 1rem;

  @include breakpoints.and-up(lg) {
    flex-direction: row;
    padding: 0rem;

    
    li {
      width: max-content;
      margin-bottom: 0;

      a {
        padding: 0.75rem 1rem;

        font-size: map.get(typography.$sizes, 'b2');
        font-weight: map.get(typography.$weights, 'b1');
        line-height: map.get(typography.$line-heights, 'b1');
      }
    }
  }
}

.logo {
  font-size: 0;

  img {
    width: 5rem;
    height: 3.5rem;
    user-select: none;
  }
}

.toggle-menu-button {
  border: 0;
  background: transparent;
  color: var(--title);
  font-size: 0;

  @include breakpoints.and-up(lg) {
    display: none;
  }
}

.btn-area-cliente {
  display: none;
  color: var(--blue700);
  font-size: map.get(typography.$sizes, 'b4');
  font-weight: map.get(typography.$weights, 'b2');
  line-height: map.get(typography.$line-heights, 'b2');
  padding: 0.75rem 1.75rem;
  width: max-content;
  text-transform: uppercase;
  transition: filter 0.2s;

  & > span {
    display: flex;
    align-items: center;  
    justify-content: center;

    column-gap: 0.25rem;
  }

  &:hover {
    filter: brightness(0.9)
  }

  &:focus-visible {
    outline-color: var(--text);
    outline-offset: 2px;
  }

  @include breakpoints.and-up(lg) {
    display: inline;
  }
}

.hide {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
  height: 0;

  @include breakpoints.and-up(lg) {
    visibility: visible;
    opacity: 1;
    height: auto;
  }
}