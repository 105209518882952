@use '../../styles/breakpoints.scss';

.title-container {
  display: grid;
  place-items: center;
  gap: 1rem;

  padding: 3rem 1rem 1rem 1rem;

  width: 100%;

  & > img {
    width: 100%;

    max-width: 300px;
  }

  & > h2 {
    text-align: center;
  }
}

.policy-container {
  display: grid;
  gap: 1rem;

  padding: 2rem 1.5rem;
  text-align: justify;

  @include breakpoints.and-up(lg) {
    padding: 1rem 1rem 3rem 0.5rem;
  }
}

