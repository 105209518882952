 .container {
    display: flex;
    text-align: justify;
    align-items: center;
    flex-direction: column;
    justify-content: baseline;

    height: 350px;
    
    padding: 0rem 2rem;

    img{
      margin-bottom: 1rem;
    }

    h4 {
      margin-bottom: 1rem;
    }
 }