@use '../../styles/breakpoints.scss';

.container{
  min-height: calc(100vh - 368px);
  padding: 0rem 0.5rem;
  
  @include breakpoints.and-up(lg) {
    padding: 0 0.5rem;
  }
}

.title-container {
  display: grid;
  place-items: center;
  gap: 1rem;

  padding: 3rem 1rem 1rem 1rem;

  width: 100%;

  & > img {
    width: 100%;

    max-width: 300px;
  }

  & > h2 {
    text-align: center;
  }
}

.lgpd-container {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.lgpd-content {
  width: 800px;

  display: grid;
  gap: 1rem;

  padding: 1rem 1rem 3rem 1rem;
  text-align: justify;
}

