@use 'sass:map';
@use '../../styles/typography.scss';
@use '../../styles/breakpoints.scss';


.content{
  padding: 3rem 1.5rem;
  
  @include breakpoints.and-up(lg) {
    padding: 3rem 0.5rem;
  }
}

.title-container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 2rem;
}

.compartilhar-container{
  display: flex;
}

.icons-compartilhar-container{
  margin-left: 0.2rem;
}

.icons-compartilhar{
  cursor: pointer;
  margin-left: 0.5rem;
}

.descricao-container{
  max-width: 750px;

  h6{
    margin-bottom: 1rem;
  }

  ul{
    margin-bottom: 1.5rem;
  }

  p{
    margin-bottom: 1.5rem;
  }

  li{
    margin-left: 1.5rem;
  }
}

.button-submit{
  background: var(--cyanGradients);
  border: 0;
  color: var(--blue700);
  font-size: map.get(typography.$sizes, 'b4');
  font-weight: map.get(typography.$weights, 'b2');
  line-height: map.get(typography.$line-heights, 'b2');
  padding: 0.75rem 1.75rem;
  width: 100%;
  text-transform: uppercase;
  transition: filter 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;

  @include breakpoints.and-up(lg) {
    width: max-content;
  }


  &:hover {
    filter: brightness(0.9)
  }

  &:focus-visible {
    outline-color: var(--text);
    outline-offset: 2px;
  }
}

.anexo-container {
  display: flex;
  gap: 1rem;

  align-items: center;

  margin-bottom: 1rem;  

  flex-direction: column;
  
  @include breakpoints.and-up(lg) {
    flex-direction: row;
  }
}

.rotate {
  animation: loadingAnimation 1s linear infinite;
  margin-right: 0.5rem;
}

@keyframes loadingAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}