@use '../../styles/breakpoints.scss';

.solucoes-container {
  display: flex;
  
  padding: 5rem 0;

  background: url('../../assets/nossas-solucoes-background.png') center center no-repeat;
  background-size: cover;

  align-items: center;
  flex-direction: column;
}

.solucoes-section {  
  width: 100%;
  height: 100%;

  margin-top: 4rem;
  padding: 0 1.5rem;
  
  @include breakpoints.and-up(lg) {
    padding: 0 0.5rem;
  }
}

.button {
  margin-top: 1rem;
  background: var(--cyanGradients);
  padding: 0.75rem 1.75rem;
  width: max-content;
  text-transform: uppercase;
  transition: filter 0.2s;
  color: var(--blue700);
  font-size: 0.875rem;

  &:hover {
    filter: brightness(0.9)
  }
}