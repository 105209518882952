@use "sass:map";
@use "../styles/breakpoints.scss";
@use "../styles/colors.scss";
@use "../styles/typography.scss";

.textarea-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.textarea-field {
  background-color: colors.$gray100;

  color: colors.$text;
  border:0;
  font-size: map.get(typography.$sizes, "b1");
  font-weight: map.get(typography.$weights, "b1");
  line-height: map.get(typography.$line-heights, "b1");
  padding: 0.5rem 0.75rem;
  outline: 0;
  width: 100%;
  min-height: 100px;
  max-height: 200px;
  resize: vertical;

  &:hover {
    border-color: colors.$gray400;
    border-bottom: 1px solid colors.$gray300;
  }

  &::placeholder {
    color: colors.$gray400;
  }

  &:focus {
    border-color: colors.$cyan500;
    box-shadow: 0 0 2px rgba(8, 103, 161, 0.5);
  }
}
