.container{
  display: flex;

  position: relative;
  
  img{
    top: 8%;
    position: absolute;
    transition: top 0.4s ease-out;
  }

  &:hover{ 
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    
    img{
      top: 35%;
     }
  }
}


.content{
  display: flex;
  padding: 1rem;

  h4{
    margin-bottom: 0.5rem;
  }
}

.column{
  flex-direction: column;
  padding-left: 5rem;
}