@use './breakpoints';
@use './colors.scss' as *;
@import './utils/colors.utilities.scss';
@import './utils/typography.utilities.scss';
@import '../../node_modules/normalize.css/normalize.css';

:root {
  --gray50: #{$gray50};
  --gray100: #{$gray100};
  --gray200: #{$gray200};
  --gray300: #{$gray300};
  --gray400: #{$gray400};
  --gray500: #{$gray500};
  --gray600: #{$gray600};
  --gray700: #{$gray700};
  --gray800: #{$gray800};
  --gray900: #{$gray900};

  --cyan50: #{$cyan50};
  --cyan100: #{$cyan100};
  --cyan200: #{$cyan200};
  --cyan300: #{$cyan300};
  --cyan400: #{$cyan400};
  --cyan500: #{$cyan500};
  --cyan600: #{$cyan600};
  --cyan700: #{$cyan700};
  --cyan800: #{$cyan800};
  --cyan900: #{$cyan900};

  --blue50: #{$blue50};
  --blue100: #{$blue100};
  --blue200: #{$blue200};
  --blue300: #{$blue300};
  --blue400: #{$blue400};
  --blue500: #{$blue500};
  --blue600: #{$blue600};
  --blue700: #{$blue700};
  --blue800: #{$blue800};
  --blue900: #{$blue900};

  --infoBlue: #{$infoBlue};
  --infoWarning: #{$infoWarning};
  --infoSuccess: #{$infoSuccess};
  --infoRed: #{$infoRed};

  --cyanGradients: #{$cyanGradients};

  --title: #{$title};
  --text: #{$text};
  --textLight: #{$textLight};

  --background: #{$background};
  --white: #{$white};
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
div#root {
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
}

html {
  font-size: 16px;

  @include breakpoints.and-down(sm) {
    font-size: 87.5%;
  }

  @include breakpoints.and-up(md) {
    font-size: 93.74%;
  }

  @include breakpoints.and-up(lg) {
    font-size: 100%;
  }
}

body {
  background: var(--background);
  font-family: 'Encode Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
}

h1 {
  margin: 0;
}

.center-container {
  max-width: 1140px;
  margin: 0 auto;
  width: 100%;
}

.w-full {
  width: 100%;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
}

:root {
  color-scheme: only light;
}