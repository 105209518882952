@use '../../styles/breakpoints.scss';

.depoimentos-container {
  display: flex;
  
  padding: 5rem 1.5rem;

  align-items: center;
  flex-direction: column;
}

.depoimentos-section {  
  width: 100%;
  height: 100%;

  margin-top: 3rem;
  padding: 0 1.5rem;
}